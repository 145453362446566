<template>
  <div v-if="question">
    <span v-for="(answer, index) of question.answers" :key="answer.text">
      <span v-if="answer.textBefore && !answer.renderEditfield" v-html="answer.textBefore"
            style="font-size: 14px; position: relative; top: -6px; padding-left: 1rem;" :class="getClass()"></span>
      <md-checkbox v-if="answer.renderCheckbox" v-model="answer.checked"
                   style="margin-left: 13px;width: 38px" :class="getClass()" :disabled="comparisonActive === 'true'">
        <span v-if="answer.showPoints">{{answer.points}}</span>
      </md-checkbox>
      <div v-else-if="answer.renderEditfield" style="padding: 0 1rem; width: 70%;">
        <md-field style="display: block;">
          <label :class="getLabelClass()">{{answer.textBefore}}</label>
          <md-input v-model="answer.textOfEditfield" :class="getClass()" :disabled="comparisonActive === 'true'"></md-input>
        </md-field>
      </div>
      <div v-else-if="answer.renderTextarea" style="padding: 0 1rem; width: 70%;">
        <md-field style="display: block;">
          <md-textarea v-model="answer.textOfTextarea" :class="getLabelClass()" :disabled="comparisonActive === 'true'"></md-textarea>
        </md-field>
      </div>
      <md-radio v-else v-model="question.answerValue" :value="answer.points" @change="onChangeAnswerValue" :class="getClass()" :disabled="comparisonActive === 'true'">
        <span v-html="answer.text" :class="getClass()"></span>
        <md-tooltip md-direction="top" style="font-size: medium;" v-if="answer.text && comparisonActive !== 'true'">{{answer.text}}</md-tooltip>
      </md-radio>
      <span v-if="question.answerValue === answer.points && answer.nestedAnswers.length > 0">
        <QuestionAnswersView :question="{answers: answer.nestedAnswers, answerValue: answer.nestedAnswerValue, answerIndex: index}"
                             @setAnswerValue="onSetAnswerValue" :comparisonActive="comparisonActive"></QuestionAnswersView>
      </span>
    </span>
  </div>
</template>

<script>
  import QuestionAnswersView from './QuestionAnswersView';

  export default {
    name: 'QuestionAnswersView',

    components: {
      QuestionAnswersView,
    },

    props: {
      question: {
        required: true
      },
      comparisonActive: {
        required: true
      }
    },

    methods: {
      onChangeAnswerValue() {
        this.$emit('setAnswerValue', {answerValue: this.question.answerValue, answerIndex: this.question.answerIndex});
      },

      onSetAnswerValue(data) {
        this.question.answers[data.answerIndex].nestedAnswerValue = data.answerValue;
      },

      getClass() {
        return this.comparisonActive === 'true' ? 'highlight-comparison-cell' : 'md-primary';
      },

      getLabelClass() {
        return this.comparisonActive === 'true' ? 'disabled-comparison-cell' : 'md-primary';
      }
    }
  }
</script>

<style lang="scss" scoped>
  p {
    margin-bottom: 0;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-size: 0.9rem;
  }

  .highlight-comparison-cell, .md-theme-dark .highlight-comparison-cell {
    color: cornflowerblue;
    -webkit-text-fill-color: cornflowerblue !important;
    font-style: italic;
    font-size: unset !important;
    font-weight: unset !important;
  }

  .disabled-comparison-cell, .md-theme-dark .disabled-comparison-cell {
    color: #7b7b7b;
    font-style: italic;
    font-size: unset !important;
    font-weight: unset !important;
  }
</style>