<template>
  <div class="page-container">
    <EditDocumentationDialog :mode="dialogMode" @updateDocumentationSuccess="onUpdateDocumentationSuccess"
                             :doc="selectedDocumentation" :fullDoc="fullDocumentation" :child="child"></EditDocumentationDialog>
  </div>
</template>

<script>
  import EditDocumentationDialog from '../components/dialogs/EditDocumentationDialog'

  export default {
    name: 'Documentation',

    components: {
      EditDocumentationDialog,
    },

    created() {
    },

    destroyed() {
    },

    mounted() {
      this.restoreUserSettings();
    },

    data: () => ({
      archiveActive: false,
      dialogMode: 'update',

      child: null,
      selectedDocumentation: null,
      fullDocumentation: null,
    }),

    methods: {

      onUpdateDocumentationSuccess(updatedDocumentation) {
        this.selectedDocumentation = null;
        setTimeout(() => {
          this.selectedDocumentation = updatedDocumentation;
          this.dialogMode = 'update';
        }, 50)
      },

      restoreUserSettings() {
        this.archiveActive = (localStorage.getItem(this.user.md5 + '@archiveActive') === 'true');

        if (localStorage.getItem(this.user.md5 + '@selectedDocumentation')) {
          this.selectedDocumentation = JSON.parse(localStorage.getItem(this.user.md5 + '@selectedDocumentation'));
        }

        if (localStorage.getItem(this.user.md5 + '@fullDocumentation')) {
          this.fullDocumentation = JSON.parse(localStorage.getItem(this.user.md5 + '@fullDocumentation'));
        }

        if (localStorage.getItem(this.user.md5 + '@selectedChild')) {
          this.child = JSON.parse(localStorage.getItem(this.user.md5 + '@selectedChild'));
        }

        if (localStorage.getItem(this.user.md5 + '@dialogMode')) {
          this.dialogMode = localStorage.getItem(this.user.md5 + '@dialogMode');
        }
      },
    },

    computed: {

      labels() {
        return this.$store.getters.labels
      },

      user() {
        return this.$store.getters.user;
      },
    }
  }
</script>

<style ang="scss" scoped>
</style>
