import HttpInterceptor from "./HttpInterceptor";

export default {

  name: 'DocumentationService',

  getDocumenation(childId) {
    return HttpInterceptor.httpClient().get('/documentation/child/' + childId + '/all');
  },

  getPreFilledDocumentation(childId, type) {
    return HttpInterceptor.httpClient().get('/documentation/child/' + childId + '/prefill?type=' + type);
  },

  addDocumentation(childId, documentation)  {
    return HttpInterceptor.httpClient().post('/documentation/child/' + childId + '/add', documentation);
  },

  updateDocumentation(childId, documentation) {
    return HttpInterceptor.httpClient().put('/documentation/child/' + childId + '/update', documentation);
  },

  evaluateDocumentation(childId, documentation) {
    return HttpInterceptor.httpClient().put('/documentation/child/' + childId + '/evaluate', documentation);
  },

  deleteDocumenation(documentationId) {
    return HttpInterceptor.httpClient().delete('/documentation/' + documentationId);
  },
}