<template>
  <span v-if="type">
    <img v-if="type === 'NOTE'" :style="svgStyle" src="../../assets/icons/note.png"/>
    <img v-else-if="type === 'CHILD_OBSERVATION'" :style="svgStyle" src="../../assets/icons/observation.png"/>
    <img v-else-if="type === 'PARENTS_TALK'" :style="svgStyle" src="../../assets/icons/parents.svg"/>
    <img v-else-if="type === 'THERAPIST_TALK'" :style="svgStyle" src="../../assets/icons/therapy.webp"/>
    <img v-else-if="type === 'QUESTIONNAIRE_PERIK'" :style="pngStyle" src="../../assets/icons/perik.png"/>
    <img v-else-if="type === 'QUESTIONNAIRE_SELDAK'" :style="pngStyle" src="../../assets/icons/seldak.png"/>
    <img v-else-if="type === 'QUESTIONNAIRE_SISMIK'" :style="pngStyle" src="../../assets/icons/sismik.png"/>
  </span>
</template>

<script>
  export default {
    name: 'DocumentationIconView',

    props: {
      type: {
        required: true
      },
      height: {
        required: false
      },
    },

    watch: {
      height() {
        this.setStyles();
      },
    },

    mounted() {
      this.setStyles();
    },

    data: () => ({
      pngStyle: 'height: 23px; opacity: 0.7;',
      svgStyle: 'height: 24px; opacity: 1;',
    }),

    methods: {

      setStyles() {
        this.pngStyle = 'height: ' + this.height + '; opacity: 0.7;';
        this.svgStyle = 'height: ' + this.height + '; opacity: 1;';
      }
    },
  }
</script>

<style lang="scss" scoped>
</style>