<template>
  <div v-if="documentation" style="width: 100%;">
    <md-app>
      <md-app-toolbar v-if="!printMode">
        <form novalidate @submit.prevent="validateDocumentation" style="width: 100%">
          <md-dialog-title>
            <div class="grid-x grid-padding-x">
              <div v-if="mode === 'update'" class="cell small-12 medium-12 large-6">
                <md-icon>edit</md-icon>&nbsp;<DocumentationIconView :type="documentation.type"/>&nbsp;&nbsp;&nbsp;
                <span
                    style="color: crimson; font-size: larger">{{
                    labels.documentationType[documentation.type].toUpperCase()
                  }}</span>
                &nbsp;für&nbsp;&nbsp;<ImageView :imageId="child.imageId" size="tiny"></ImageView>&nbsp;&nbsp;
                <span class="group-title">{{ child.masterdata.fullName }}</span>&nbsp;&nbsp;bearbeiten
              </div>
              <div v-else-if="mode === 'add'" class="cell small-12 medium-12 large-6">
                <md-icon>add</md-icon>&nbsp;<DocumentationIconView :type="documentation.type"/>&nbsp;&nbsp;&nbsp;
                <span
                    style="color: crimson; font-size: larger">{{
                    labels.documentationType[documentation.type].toUpperCase()
                  }}</span>
                &nbsp;für&nbsp;&nbsp;<ImageView :imageId="child.imageId" size="tiny"></ImageView>&nbsp;&nbsp;
                <span class="group-title">{{ child.masterdata.fullName }}</span>&nbsp;&nbsp;anlegen
              </div>
              <div class="cell large-1 medium-3 small-6">
                <md-switch v-model="darkModeState" class="md-primary" @change="toggleDarkMode">
                  <md-tooltip>Wechsel Normal- und Dunkelmodus</md-tooltip>
                  Dunkel
                </md-switch>
              </div>
              <div class="cell large-1 medium-3 small-6">
                <SessionTime :navigation="true" style="margin-top: 6px;"></SessionTime>
              </div>
              <div class="cell small-12 medium-6 large-4 text-right" style="padding-top: 6px;">
                <button v-if="mode === 'add' && canWrite && !child.archived" class="button success" type="submit"
                        :disabled="sending" style="margin-bottom: 0">
                  <i class="fi-plus"></i>&nbsp;&nbsp;&nbsp;Hinzufügen
                  <md-tooltip>Diese neu begonnene Dokumentation speichern</md-tooltip>
                </button>
                <button v-if="mode === 'update' && canWrite && !child.archived" class="button success" type="submit"
                        :disabled="sending" style="margin-bottom: 0">
                  <i class="fi-save"></i>&nbsp;&nbsp;&nbsp;Änderungen speichern
                  <md-tooltip>Änderungen an dieser bestehenden Dokumentation speichern</md-tooltip>
                </button>
                <button v-if="!child.archived" class="button" style="margin-left: 1rem; margin-bottom: 0"
                        @click="closeDialog" type="button" :disabled="sending">
                  <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Schließen
                  <md-tooltip>Schließen, falls Änderungen vorliegen werden Sie darauf hingewiesen</md-tooltip>
                </button>
                <button v-else class="button primary" style="margin-left: 1rem; margin-bottom: 0" @click="closeDialog"
                        type="button" :disabled="sending">
                  <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Schließen
                </button>
                <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <md-button class="md-icon-button md-dense md-primary" @click="closeDialog">
                  <md-icon>close</md-icon>
                  <md-tooltip md-direction="bottom" style="font-size: medium;">
                    Ansicht schliessen und zurück
                  </md-tooltip>
                </md-button>
              </div>
            </div>
          </md-dialog-title>
        </form>
      </md-app-toolbar>

      <md-app-content>
        <md-card v-if="!printMode" class="md-card-flat">
          <md-card-expand>
            <md-card-actions md-alignment="left" style="padding: 0;">
              <md-card-expand-trigger>
                <md-button class="md-icon-button">
                  <md-icon>keyboard_arrow_down</md-icon>
                  <md-tooltip>Vergleich mit anderen {{ labels.documentationType[doc.type].toUpperCase() }}-Dokumenten
                  </md-tooltip>
                </md-button>
              </md-card-expand-trigger>
              <h5 style="padding-left: 10px;padding-top: 6px;">Dokumenten-Vergleich</h5>
            </md-card-actions>
            <md-card-expand-content>
              <md-card-content style="padding-bottom: 8px; padding-left: 0; padding-right: 0;">

                <div v-if="filteredFullDoc.length > 0" class="grid-x grid-padding-x">
                  <div class="cell large-3 medium-4 hide-for-small-only compare-title">
                    <span class="title-thin-large">Vergleichen mit:</span>
                  </div>
                  <div class="cell large-7 medium-6 small-10">
                    <md-field id="document-comparision-select">
                      <label>Dokument</label>
                      <md-select v-model="selectedDiffDocIds" autocomplete="off" :disabled="sending"
                                 @md-opened="resizeOptionsPopup()" @md-selected="selectDiffDoc()" multiple>
                        <md-option v-for="doc of filteredFullDoc" v-bind:value="doc.id" :key="doc.id">
                          {{ labels.documentationType[doc.type].toUpperCase() }}
                          ({{ doc.lastModifiedDate | moment('DD.MM.YYYY') }})
                        </md-option>
                      </md-select>
                    </md-field>
                  </div>
                  <div class="cell auto compare-reset-button">
                    <md-button @click="selectedDiffDocIds = []; selectedDiffDoc = []"
                               :disabled="selectedDiffDocIds.length === 0"
                               class="md-icon-button md-raised md-accent">
                      <md-icon>close</md-icon>
                      <md-tooltip>Vergleich beenden</md-tooltip>
                    </md-button>
                  </div>
                </div>
                <div v-else>
                  <span
                      class="title-thin-large">Keine weiteren {{
                      labels.documentationType[doc.type].toUpperCase()
                    }}-Dokumente vorhanden.</span>
                </div>

              </md-card-content>
            </md-card-expand-content>
          </md-card-expand>
          <br>
        </md-card>

        <md-tabs id="documentation-tabs" v-if="documentation.document" @md-changed="onTabChanged">
          <md-tab id="tab-cover" md-label="Deckblatt">
            <div class="grid-x grid-padding-x">
              <div class="cell small-12 medium-12 large-6">
                <br><br><br>
                <table id="document-cover-table-id">
                  <tbody>
                  <tr>
                    <td class="hide-for-small-only" style="width: 220px;">
                      <DocumentationIconView :type="documentation.type" height="200px"/>&nbsp;&nbsp;&nbsp;
                    </td>
                    <td>
                      <span
                          style="color: crimson; font-size: xx-large">{{
                          labels.documentationType[documentation.type]
                        }}</span>
                      <br><br>
                      <span style="font-size: small" v-html="coverTextFirst[documentation.type]"></span>
                      <br><br>
                      <span style="font-size: large" v-html="coverTextSecond[documentation.type]"></span>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <div v-if="documentation.type.includes('QUESTIONNAIRE')">
                  <br>
                  <span style="font-size: larger; font-weight: lighter"></span>
                  <span style="font-size: small; font-weight: lighter">Staatsinstitut für Frühpädagogik IFP</span>
                </div>
              </div>
              <div class="cell small-12 medium-12 large-6">
                <br><br>
                <div class="grid-x grid-padding-x">
                  <div class="cell small-8">
                    <md-field>
                      <label>Name des Kindes</label>
                      <md-input v-model="child.masterdata.fullName" disabled></md-input>
                    </md-field>
                  </div>
                  <div class="cell small-4">
                    <md-field>
                      <label>Alter des Kindes</label>
                      <md-input v-model="child.ageTextual" disabled></md-input>
                    </md-field>
                  </div>
                </div>
                <div class="cell small-12">
                  <md-field v-if="availableEmployees && availableEmployees.length > 0">
                    <label>Fachkraft/-kräfte, die den Bogen hauptsächlich ausgefüllt hat/haben</label>
                    <md-select v-model="documentation.editors" multiple>
                      <md-option v-for="employee of availableEmployees"
                                 :value="employee.masterdata.fullName"
                                 :key="getTableDataKey(parseInt(employee.id, 16), 1000)">
                        {{ employee.masterdata.fullName }} <i v-if="employee.exited">(nicht gefunden)</i>
                      </md-option>
                    </md-select>
                  </md-field>
                  <div v-else>
                    <p class="md-dialog-title ai-alert-text"><b>ACHTUNG:</b> Keine Mitarbeiter in der Einrichtung des
                      Kindes gefunden!</p><br>
                  </div>
                </div>
                <div v-if="selectedDiffDoc.length > 0">
                  <div v-for="(diffDoc, diffDocIndex) of selectedDiffDoc"
                       :key="getTableDataKey(parseInt(diffDoc.id, 16), 2000 * (diffDocIndex + 1))"
                       class="cell small-12">
                    <md-field v-if="availableEmployees && availableEmployees.length > 0">
                      <label>
                        <i style="color: cornflowerblue">Fachkraft/-kräfte, die den Bogen hauptsächlich ausgefüllt
                          hat/haben
                          <span>({{ diffDoc.lastModifiedDate | moment('DD.MM.YYYY') }})
                            <md-tooltip md-direction="top"
                                        style="font-size: medium;">zuletzt geändert am {{
                                diffDoc.lastModifiedDate | moment('DD.MM.YYYY')
                              }} um {{ diffDoc.lastModifiedDate | moment('LTS') }} Uhr</md-tooltip>
                          </span>
                        </i>
                      </label>
                      <md-select v-model="diffDoc.editors" multiple disabled>
                        <md-option v-for="employee of availableEmployees"
                                   :value="employee.masterdata.fullName"
                                   :key="getTableDataKey(parseInt(employee.id, 16), 3000 * (diffDocIndex + 1))">
                          {{ employee.masterdata.fullName }} <i v-if="employee.exited">(nicht gefunden)</i>
                        </md-option>
                      </md-select>
                    </md-field>
                    <div v-else>
                      <p class="md-dialog-title ai-alert-text"><b>ACHTUNG:</b> Keine Mitarbeiter in der Einrichtung des
                        Kindes gefunden!</p><br>
                    </div>
                  </div>
                </div>
                <div v-if="documentation.type === 'THERAPIST_TALK'" class="cell small-12">
                  <br>
                  <md-chips v-model="documentation.document.participants" md-placeholder="Teilnehmer hinzufügen...">
                    <label style="top: -12px;">Teilnehmer, die das Gespräch begeitet haben</label>
                  </md-chips>
                </div>
                <div v-if="documentation.type.includes('PARENTS_TALK')" class="cell small-12">
                  <md-field v-if="child.relatedPersons && child.relatedPersons.length > 0">
                    <label>Eltern und Verwandte, die bei dem {{ labels.documentationType[documentation.type] }} anwesend
                      waren</label>
                    <md-select v-model="documentation.document.participants" multiple>
                      <md-option v-for="relative of child.relatedPersons"
                                 :value="relative.fullName" :key="relative.id">
                        {{ relative.fullName }}
                      </md-option>
                    </md-select>
                  </md-field>
                  <div v-else>
                    <p class="md-dialog-title ai-alert-text"><b>ACHTUNG:</b> Keine Angehörigen für das Kind angegeben!
                    </p><br>
                  </div>
                </div>

                <div class="cell small-12">
                  <div class="md-layout">
                    <div class="md-layout-item" style="padding-right: 15px;">
                      <md-datepicker id="start-editing-date-dp" :md-debounce="10000" :md-open-on-focus="false"
                                     v-model="startEditingDate" md-immediately :disabled="sending"
                                     :class="getValidationClass('startEditing')">
                        <label>Beginn der Bearbeitung</label>
                        <span class="md-error" v-if="!$v.documentation.startEditing.required">Datum für Bearbeitungsbegin ist notwendig</span>
                      </md-datepicker>
                    </div>
                    <div class="md-layout-item" style="padding-left: 15px;">
                      <md-datepicker id="end-editing-date-dp" :md-debounce="10000" :md-open-on-focus="false"
                                     v-model="endEditingDate" md-immediately :disabled="sending"
                                     :class="getValidationClass('endEditing')"
                                     @md-opened="onOpenEndEditingDatePicker">
                        <label>Ende der Bearbeitung</label>
                        <span class="md-error" v-if="!$v.documentation.endEditing.required">Datum für Bearbeitungsende ist notwendig</span>
                      </md-datepicker>
                    </div>
                  </div>
                </div>

                <div v-if="selectedDiffDoc.length > 0">
                  <div v-for="(diffDoc, diffDocIndex) of selectedDiffDoc"
                       :key="getTableDataKey(parseInt(diffDoc.id, 16), 4000 * (diffDocIndex + 1))"
                       class="cell small-12">
                    <label class="ai-standard-text">
                      <i>{{ labels.documentationType[diffDoc.type].toUpperCase() }}
                        <span>({{ diffDoc.lastModifiedDate | moment('DD.MM.YYYY') }})
                          <md-tooltip md-direction="top"
                                      style="font-size: medium;">zuletzt geändert am {{
                              diffDoc.lastModifiedDate | moment('DD.MM.YYYY')
                            }} um {{ diffDoc.lastModifiedDate | moment('LTS') }} Uhr</md-tooltip>
                        </span>
                      </i>
                    </label>
                    <div class="md-layout">
                      <div class="md-layout-item" style="padding-right: 15px;">
                        <label class="ai-standard-text"><i>Beginn der Bearbeitung:
                          {{ diffDoc.startEditing | moment('DD.MM.YYYY') }}</i></label>
                      </div>
                      <div class="md-layout-item" style="padding-left: 15px;">
                        <label class="ai-standard-text"><i>Ende der Bearbeitung:
                          {{ diffDoc.endEditing | moment('DD.MM.YYYY') }}</i></label>
                      </div>
                    </div>
                    <br>
                  </div>
                </div>

              </div>
            </div>
          </md-tab>
          <md-tab v-if="documentation.type.includes('QUESTIONNAIRE')" id="tab-concept" md-label="Einleitung">
            <div v-if="documentation.document && documentation.document.questionnaire" class="md-layout md-gutter">
              <div v-if="documentation.document.questionnaire.concept" class="md-layout-item">
                <md-card>
                  <md-card-content>
                    <span class="concept" v-html="documentation.document.questionnaire.concept"></span>
                  </md-card-content>
                </md-card>
              </div>
              <div v-if="documentation.document.questionnaire.hints" class="md-layout-item">
                <md-card>
                  <md-card-content>
                    <span class="concept" v-html="documentation.document.questionnaire.hints"></span>
                  </md-card-content>
                </md-card>
              </div>
            </div>
          </md-tab>
          <md-tab v-if="documentation.type.includes('QUESTIONNAIRE')" id="tab-questionnaire" md-label="Fragebogen">
            <md-tabs v-if="documentation.document && documentation.document.questionnaire">
              <md-tab v-for="(mainSection) of documentation.document.questionnaire.mainSections" :key="mainSection.mark"
                      :md-label="getSectionLabel(mainSection)" :md-icon="getSectionIcon(mainSection)">
                <div>
                  <md-steppers v-if="mainSection" md-vertical md-dynamic-height @md-changed="onStepChanged">

                    <md-step v-for="(section, index) of mainSection.sections" :key="getTableDataKey(index, 100)"
                             :id="section.mark" :md-label="section.heading">

                      <h5 v-if="section.subheading" v-html="section.subheading"></h5><br>

                      <table class="hover">
                        <thead>
                        </thead>
                        <tbody>
                        <tr v-for="(question, qi) of section.questions" :key="getTableDataKey(qi, 200)">
                          <td style="width: 20px;"><p>{{ question.number }}</p></td>

                          <td v-if="question.layout === 'flat'" style="width: 100%" colspan="3">
                            <p v-if="question.textAbove" v-html="question.textAbove"></p>
                            <div v-if="question.renderAdditionalEditfield" style="padding: 0 1rem; width: 70%;">
                              <md-field style="display: block;">
                                <label v-html="question.text"></label>
                                <md-input v-model="question.textOfAdditionalEditfield"></md-input>
                              </md-field>
                            </div>
                            <p v-else v-html="question.text"></p>

                            <QuestionAnswersView :question="question" comparisonActive="false"></QuestionAnswersView>

                            <div v-if="question.showAdditionalCheckbox">
                              <span
                                  style="margin: 15px; position: relative; top: -5px; font-size: 14px;">{{
                                  question.additionalCheckboxText
                                }}</span>
                              <md-checkbox v-model="question.additionalCheckboxState"
                                           style="margin-top: 6px;" class="md-primary"></md-checkbox>
                            </div>
                            <p v-if="question.textBelow" v-html="question.textBelow"></p>

                            <div v-if="selectedDiffDoc.length > 0" v-for="(diffDoc, diffDocIndex) of selectedDiffDoc"
                                 :key="getTableDataKey(parseInt(diffDoc.id, 16), 5000 * (diffDocIndex + 1))">

                              <p v-if="diffQuestionFor(question, diffDoc).textAbove" class="disabled-comparison-cell"
                                 v-html="diffQuestionFor(question, diffDoc).textAbove"></p>
                              <div v-if="diffQuestionFor(question, diffDoc).renderAdditionalEditfield"
                                   style="padding: 0 1rem; width: 70%;">
                                <md-field style="display: block;" disabled>
                                  <label class="disabled-comparison-cell"
                                         v-html="diffQuestionFor(question, diffDoc).text"></label>
                                  <md-input class="highlight-comparison-cell" disabled>
                                    {{ diffQuestionFor(question, diffDoc).textOfAdditionalEditfield }}
                                  </md-input>
                                </md-field>
                              </div>
                              <p v-else>
                                <br><span class="disabled-comparison-cell"
                                          v-html="diffQuestionFor(question, diffDoc).text"></span>
                              </p>

                              <QuestionAnswersView :question="diffQuestionFor(question, diffDoc)"
                                                   comparisonActive="true"></QuestionAnswersView>
                              <p class="highlight-comparison-cell" style="text-align: right;"><span>({{
                                  diffDoc.lastModifiedDate | moment('DD.MM.YYYY')
                                }})
                                <md-tooltip md-direction="top"
                                            style="font-size: medium;">zuletzt geändert am {{
                                    diffDoc.lastModifiedDate | moment('DD.MM.YYYY')
                                  }} um {{ diffDoc.lastModifiedDate | moment('LTS') }} Uhr</md-tooltip></span>
                              </p>
                            </div>
                          </td>

                          <td v-if="question.layout === 'normal'" style="width: 400px">
                            <p v-if="question.textAbove" v-html="question.textAbove"></p>
                            <p v-html="question.text"></p>
                            <p v-if="question.textBelow" v-html="question.textBelow"></p>
                          </td>

                          <td v-if="question.layout === 'normal'" style="width: 270px;">
                            <span v-for="(answer, index) of question.answers" :key="getTableDataKey(index, 6000)"
                                  @click="question.answerValue = -1">
                              <p v-if="answer.points === question.answerValue"
                                 style="color: green; padding-top: 10px; padding-bottom: 10px;">
                                <span class="reset-cell" v-html="answer.text"></span>
                              </p>
                              <p v-else-if="question.answerValue < 0 && index === 0"
                                 style="color: crimson">keine Angabe</p>
                              <md-tooltip v-if="answer.points === question.answerValue" md-direction="top"
                                          style="font-size: medium;">Wert zurücksetzen</md-tooltip>
                            </span>

                            <div v-if="selectedDiffDoc.length > 0">
                              <div v-if="question.showAdditionalCheckbox"><br><br></div>
                              <div v-for="(diffDoc, diffDocIndex) of selectedDiffDoc"
                                   :key="getTableDataKey(parseInt(diffDoc.id, 16), 7000 * (diffDocIndex + 1))">
                                <span v-for="(answer, index) of diffQuestionFor(question, diffDoc).answers"
                                      :key="getTableDataKey(index, 8000 * (diffDocIndex + 1))">
                                  <p v-if="answer.points === diffQuestionFor(question, diffDoc).answerValue"
                                     style="color: cornflowerblue; padding-top: 10px; padding-bottom: 10px;">
                                    <i><span v-html="answer.text"></span></i>
                                  </p>
                                  <p v-else-if="diffQuestionFor(question, diffDoc).answerValue < 0 && index === 0"
                                     style="color: cornflowerblue">keine Angabe</p>
                                </span>
                                <div v-if="question.showAdditionalCheckbox"><br><br></div>
                              </div>
                            </div>
                          </td>

                          <td v-if="question.layout === 'normal'" :style="getAnswersSize(question.answers.length)">
                            <span v-for="(answer, a_index) of question.answers" :key="getTableDataKey(a_index, 9000)">
                              <md-checkbox v-if="answer.renderCheckbox" v-model="answer.checked"
                                           style="margin-left: 13px;width: 38px" class="md-primary">
                                <span v-if="answer.showPoints" v-html="answer.text"></span>
                              </md-checkbox>
                              <md-radio v-else v-model="question.answerValue" :value="answer.points" style="width: 40px"
                                        class="md-primary">
                                <span v-if="answer.showPoints">{{ answer.points }}</span>
                              </md-radio>
                              <md-tooltip md-direction="top"
                                          style="font-size: medium;" v-html="answer.text"></md-tooltip>
                              &nbsp;&nbsp;
                            </span>
                            <div v-if="question.showAdditionalCheckbox">
                              <span
                                  style="margin: 15px; position: relative; top: -5px; font-size: 14px;">{{
                                  question.additionalCheckboxText
                                }}</span>
                              <md-checkbox v-model="question.additionalCheckboxState"
                                           style="margin-top: 6px;" class="md-primary"></md-checkbox>
                            </div>

                            <div v-if="selectedDiffDoc.length > 0">
                              <div v-for="(diffDoc, diffDocIndex) of selectedDiffDoc"
                                   :key="getTableDataKey(parseInt(diffDoc.id, 16), 10000 * (diffDocIndex + 1))">
                                <span v-for="(answer, index) of diffQuestionFor(question, diffDoc).answers"
                                      :key="getTableDataKey(index, 11000 * (diffDocIndex + 10))">
                                  <md-checkbox v-if="answer.renderCheckbox" v-model="answer.checked"
                                               style="margin-left: 13px;width: 38px" class="md-primary" disabled>
                                    <i><span v-if="answer.showPoints" v-html="answer.text"
                                             style="color: cornflowerblue"></span></i>
                                  </md-checkbox>
                                  <md-radio v-else
                                            v-model="questions[question.number + question.index + diffDoc.id].answerValue"
                                            :value="answer.points" style="width: 40px" class="md-primary" disabled>
                                    <i><span v-if="answer.showPoints"
                                             style="color: cornflowerblue">{{ answer.points }}</span></i>
                                  </md-radio>
                                  <md-tooltip md-direction="top" style="font-size: medium;"
                                              v-html="answer.text"></md-tooltip>
                                  &nbsp;&nbsp;
                                </span>
                                <i>
                                  <span class="time-stamp">{{ diffDoc.lastModifiedDate | moment('DD.MM.YYYY') }}
                                    <md-tooltip md-direction="top"
                                                style="font-size: medium;">zuletzt geändert am {{
                                        diffDoc.lastModifiedDate | moment('DD.MM.YYYY')
                                      }} um {{ diffDoc.lastModifiedDate | moment('LTS') }} Uhr</md-tooltip>
                                  </span>
                                </i>
                                <div v-if="diffQuestionFor(question, diffDoc).showAdditionalCheckbox">
                                  <span
                                      style="margin: 15px; position: relative; top: -5px; font-size: 14px;">{{
                                      diffQuestionFor(question, diffDoc).additionalCheckboxText
                                    }}</span>
                                  <md-checkbox
                                      v-model="questions[question.number + question.index + diffDoc.id].answerValue"
                                      style="margin-top: 6px;" class="md-primary" disabled></md-checkbox>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                        </tbody>
                      </table>

                      <md-field v-if="section" style="display: block;">
                        <label v-html="section.notesHeading"></label>
                        <md-textarea v-model="section.notes"></md-textarea>
                      </md-field>

                      <div v-if="selectedDiffDoc.length > 0">
                        <div v-for="(diffDoc, diffDocIndex) of selectedDiffDoc"
                             :key="getTableDataKey(parseInt(diffDoc.id, 16), 12000 * (diffDocIndex + 1))"
                             class="grid-x grid-padding-x">
                          <label class="ai-standard-text">
                            <i>{{ labels.documentationType[diffDoc.type].toUpperCase() }}
                              <span>({{ diffDoc.lastModifiedDate | moment('DD.MM.YYYY') }})
                                <md-tooltip md-direction="top"
                                            style="font-size: medium;">zuletzt geändert am {{
                                    diffDoc.lastModifiedDate | moment('DD.MM.YYYY')
                                  }} um {{ diffDoc.lastModifiedDate | moment('LTS') }} Uhr</md-tooltip>
                              </span>
                            </i>
                          </label>
                          <md-field v-if="diffSectionFor(section, diffDoc)" style="display: block;">
                            <label v-html="diffSectionFor(section, diffDoc).notesHeading"></label>
                            <md-textarea v-model="sections[section.mark + section.heading + diffDoc.id].notes"
                                         style="-webkit-text-fill-color: cornflowerblue" disabled></md-textarea>
                          </md-field>
                        </div>
                      </div>

                    </md-step>

                  </md-steppers>
                </div>
              </md-tab>
            </md-tabs>
          </md-tab>
          <md-tab v-if="documentation.type.includes('QUESTIONNAIRE')" id="tab-summary" md-label="Zusammenfassung">
            <div v-if="documentation.document && documentation.document.questionnaire">
              <md-card>
                <md-card-content>
                  <span class="concept">
                    <h2>{{ documentation.document.questionnaire.footer.heading }}</h2>
                    <p>{{ documentation.document.questionnaire.footer.subheading }}</p>
                  </span>
                </md-card-content>
              </md-card>
              <br>
              <div class="grid-x grid-padding-x">
                <div v-for="(element, index) of documentation.document.questionnaire.footer.elements"
                     :key="getTableDataKey(index, 13000)"
                     class="cell small-12 medium-6">
                  <md-field v-if="element" style="display: block;">
                    <label>{{ element.heading }}</label>
                    <md-textarea v-model="element.content"></md-textarea>
                  </md-field>
                </div>
              </div>
              <br>
              <div v-if="selectedDiffDoc.length > 0">
                <div v-for="(diffDoc, diffDocIndex) of selectedDiffDoc"
                     :key="getTableDataKey(parseInt(diffDoc.id, 16), 14000 * (diffDocIndex + 1))"
                     class="grid-x grid-padding-x">
                  <div class="cell">
                    <label class="ai-standard-text">
                      <i>{{ labels.documentationType[diffDoc.type].toUpperCase() }}
                        <span>({{ diffDoc.lastModifiedDate | moment('DD.MM.YYYY') }})
                          <md-tooltip md-direction="top"
                                      style="font-size: medium;">zuletzt geändert am {{
                              diffDoc.lastModifiedDate | moment('DD.MM.YYYY')
                            }} um {{ diffDoc.lastModifiedDate | moment('LTS') }} Uhr</md-tooltip>
                        </span>
                      </i>
                    </label>
                  </div>
                  <div v-for="(element, index) of diffDoc.document.questionnaire.footer.elements"
                       :key="getTableDataKey(index, 15000 * (diffDocIndex + 1))"
                       class="cell small-12 medium-6">
                    <md-field v-if="element" style="display: block;">
                      <label>{{ element.heading }}</label>
                      <md-textarea v-model="element.content" style="-webkit-text-fill-color: cornflowerblue"
                                   disabled></md-textarea>
                    </md-field>
                  </div>
                </div>
              </div>
            </div>
          </md-tab>
          <md-tab v-if="documentation.type.includes('QUESTIONNAIRE')" id="tab-evaluation" md-label="Auswertung">
            <div class="grid-x" style="height: 0;">
              <div class="cell text-right large-6 medium-8 small-12">
                <md-button v-if="!printMode" class="md-icon-button md-raised md-primary" @click="onPrint">
                  <md-icon>print</md-icon>
                  <md-tooltip md-direction="bottom" style="font-size: medium;">
                    Tabelle jetzt drucken
                  </md-tooltip>
                </md-button>
              </div>
              <div class="cell large-6 medium-4 small-0"></div>
            </div>

            <div v-if="documentation.evaluation && documentation.evaluation.evaluationTopics">
              <md-tabs id="perik-evaluation-tabs" v-if="documentation.type === 'QUESTIONNAIRE_PERIK'">
                <md-tab id="sheet-one-scale-values-perik" md-label="Summenwerte">
                  <table class="hover evaluation-topics">
                    <tr>
                      <td colspan="6" style="border-right: 0;">Name des Kindes:
                        <span>{{ child.masterdata.fullName }}</span></td>
                      <td colspan="2" style="border-left: 0;">Datum:
                        <span>{{ documentation.endEditing | moment('DD.MM.YYYY') }}</span></td>
                    </tr>
                    <tr>
                      <td colspan="6"><b>Fragen</b></td>
                      <td style="text-align: center"><b>Summe</b></td>
                      <td><b>Entwicklungsbereich</b></td>
                    </tr>
                    <tbody v-for="(et, index) of documentation.evaluation.evaluationTopics"
                           :key="getTableDataKey(index, 16000 + index * 50)">
                    <tr>
                      <td v-for="i in (6 - et.questions.length)" :key="getTableDataKey(i, 17000 + index * 50)"></td>
                      <td style="text-align: center;" v-for="(q, ind) of et.questions" v-html="q.index"
                          :key="getTableDataKey(ind, 18000 + index * 50)"></td>
                      <td></td>
                      <td v-html="et.topic"></td>
                    </tr>
                    <tr>
                      <td v-for="i in (6 - et.questions.length)" :key="getTableDataKey(i, 19000 + index * 50)"></td>
                      <td style="text-align: center;" class="highlight-cell" v-for="(q, ind) of et.questions"
                          v-html="q.score" :key="getTableDataKey(ind, 13000 + index * 50)"></td>
                      <td style="text-align: center;" class="highlight-cell" v-html="et.totalSum"></td>
                      <td></td>
                    </tr>
                    <tr v-if="selectedDiffDoc.length > 0" v-for="(diffDoc, diffDocIndex) of selectedDiffDoc"
                        :key="getTableDataKey(parseInt(diffDoc.id, 16), 20000 * (diffDocIndex + 1))" class="even-row">
                      <td v-for="i in (6 - diffDoc.evaluation.evaluationTopics[index].questions.length)"
                          :key="getTableDataKey(i, (21000 + index * 50)  * (diffDocIndex + 1))"></td>
                      <td style="text-align: center;" class="highlight-comparison-cell"
                          v-for="(q, ind) of diffDoc.evaluation.evaluationTopics[index].questions" v-html="q.score"
                          :key="getTableDataKey(ind, 22000 + index * 50)"></td>
                      <td style="text-align: center;" class="highlight-comparison-cell"
                          v-html="diffDoc.evaluation.evaluationTopics[index].totalSum"></td>
                      <td class="highlight-comparison-cell">{{ labels.documentationType[diffDoc.type].toUpperCase() }}
                        ({{ diffDoc.lastModifiedDate | moment('DD.MM.YYYY') }})
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </md-tab>
                <md-tab id="sheet-two-norm-table-perik" md-label="Gruppenzuordnung">
                  <table class="hover evaluation-topics">
                    <tr>
                      <td colspan="2" style="border-right: 0;">Name des Kindes:
                        <span>{{ child.masterdata.fullName }}</span></td>
                      <td colspan="3" style="border-left: 0;">Datum:
                        <span>{{ documentation.endEditing | moment('DD.MM.YYYY') }}</span></td>
                    </tr>
                    <tr>
                      <td colspan="1"><b>Entwicklungsbereich</b></td>
                      <td colspan="1" style="text-align: center;"><b>Summe</b></td>
                      <td colspan="1" style="text-align: center;"><b>Gruppe 1<br>obere<br>25%</b></td>
                      <td colspan="1" style="text-align: center;"><b>Gruppe 2<br>mittlere<br>50%</b></td>
                      <td colspan="1" style="text-align: center;"><b>Gruppe 3<br>untere<br>25%</b></td>
                    </tr>
                    <tbody v-for="(et, index) of documentation.evaluation.evaluationTopics"
                           :key="getTableDataKey(index, 23000)">
                    <tr>
                      <td v-html="et.topic"></td>
                      <td v-html="et.sum" style="text-align: center;" class="highlight-cell"></td>
                      <td v-for="groupNum in 3" :key="getTableDataKey(groupNum, 24000 + index * 20)"
                          style="text-align: center;">
                        <b v-if="et.primaryComparisonGroup === 'Gruppe ' + groupNum" class="highlight-cell">Gruppe
                          {{ groupNum }}</b>
                        <b v-else-if="et.higherComparisonGroup === 'Gruppe ' + groupNum" class="highlight-less-cell">Gruppe
                          {{ groupNum }}&nbsp;&#9664;</b>
                        <b v-else-if="et.lowerComparisonGroup === 'Gruppe ' + groupNum" class="highlight-less-cell">&#9654;&nbsp;Gruppe
                          {{ groupNum }}</b>
                        <i v-else style="color: lightgray">Gruppe {{ groupNum }}</i>
                      </td>
                    </tr>
                    <tr v-if="selectedDiffDoc.length > 0" v-for="(diffDoc, diffDocIndex) of selectedDiffDoc"
                        :key="getTableDataKey(parseInt(diffDoc.id, 16), 25000 * (diffDocIndex + 1))" class="odd-row">
                      <td>{{ diffDoc.evaluation.evaluationTopics[index].topic }} <span
                          class="highlight-comparison-cell">{{ labels.documentationType[diffDoc.type].toUpperCase() }} ({{
                          diffDoc.lastModifiedDate | moment('DD.MM.YYYY')
                        }})</span></td>
                      <td v-html="diffDoc.evaluation.evaluationTopics[index].sum" style="text-align: center;"
                          class="highlight-comparison-cell"></td>
                      <td v-for="groupNum in 3"
                          :key="getTableDataKey(groupNum, (26000 + index * 20)  * (diffDocIndex + 1))"
                          style="text-align: center;">
                        <b v-if="diffDoc.evaluation.evaluationTopics[index].primaryComparisonGroup === 'Gruppe ' + groupNum"
                           class="highlight-comparison-cell">Gruppe {{ groupNum }}</b>
                        <b v-else-if="diffDoc.evaluation.evaluationTopics[index].higherComparisonGroup === 'Gruppe ' + groupNum"
                           class="highlight-less-comparison-cell">Gruppe {{ groupNum }}&nbsp;&#9664;</b>
                        <b v-else-if="diffDoc.evaluation.evaluationTopics[index].lowerComparisonGroup === 'Gruppe ' + groupNum"
                           class="highlight-less-comparison-cell">&#9654;&nbsp;Gruppe {{ groupNum }}</b>
                        <i v-else style="color: lightgray">Gruppe {{ groupNum }}</i>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </md-tab>
              </md-tabs>
              <md-tabs id="sismik-evaluation-tabs" v-if="documentation.type === 'QUESTIONNAIRE_SISMIK'">
                <md-tab id="sheet-one-scale-values-sismik" md-label="Skalenwerte">
                  <table class="hover evaluation-topics">
                    <tr>
                      <td colspan="17">Name des Kindes: <span
                          class="highlight-cell">{{ child.masterdata.fullName }}</span></td>
                    </tr>
                    <tr>
                      <td colspan="15"><b>Fragen</b></td>
                      <td><b>Summe</b></td>
                      <td><b>Skala</b></td>
                    </tr>
                    <tbody v-for="(et, index) of documentation.evaluation.evaluationTopics"
                           :key="getTableDataKey(index, 27000 + index * 50)">
                    <tr>
                      <td v-for="i in (15 - et.questions.length)" :key="getTableDataKey(i, 28000 + index * 50)"></td>
                      <td style="text-align: center;" v-for="(q, ind) of et.questions" v-html="q.index"
                          :key="getTableDataKey(ind, 29000 + index * 50)"></td>
                      <td></td>
                      <td v-html="et.topic"></td>
                    </tr>
                    <tr>
                      <td v-for="i in (15 - et.questions.length)" :key="getTableDataKey(i, 30000 + index * 50)"></td>
                      <td style="text-align: center;" class="highlight-cell" v-for="(q, ind) of et.questions"
                          v-html="q.score" :key="getTableDataKey(ind, 31000 + index * 50)"></td>
                      <td style="text-align: center;" class="highlight-cell" v-html="et.totalSum"></td>
                      <td></td>
                    </tr>
                    <tr v-if="selectedDiffDoc.length > 0" v-for="(diffDoc, diffDocIndex) of selectedDiffDoc"
                        :key="getTableDataKey(parseInt(diffDoc.id, 16), 32000 * (diffDocIndex + 1))" class="even-row">
                      <td v-for="i in (15 - et.questions.length)"
                          :key="getTableDataKey(i, (33000 + index * 50) * (diffDocIndex + 1))"></td>
                      <td style="text-align: center;" class="highlight-comparison-cell"
                          v-for="(q, ind) of diffDoc.evaluation.evaluationTopics[index].questions" v-html="q.score"
                          :key="getTableDataKey(ind, 34000 + index * 50)"></td>
                      <td style="text-align: center;" class="highlight-comparison-cell"
                          v-html="diffDoc.evaluation.evaluationTopics[index].totalSum"></td>
                      <td class="highlight-comparison-cell">{{ labels.documentationType[diffDoc.type].toUpperCase() }}
                        ({{ diffDoc.lastModifiedDate | moment('DD.MM.YYYY') }})
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </md-tab>
                <md-tab id="sheet-two-norm-table-sismik" md-label="Normtabelle">
                  <table class="hover evaluation-topics">
                    <tr>
                      <td colspan="6">Name des Kindes: <span
                          class="highlight-cell">{{ child.masterdata.fullName }}</span></td>
                    </tr>
                    <tr>
                      <td v-for="(et, index) of documentation.evaluation.evaluationTopics"
                          :key="getTableDataKey(index, 35000)" style="width: 16%">
                        <b>{{ et.topic.replace(',', ', ') }}</b></td>
                    </tr>
                    <tbody v-for="groupNum in 6" :key="getTableDataKey(groupNum, 36000)">
                    <tr>
                      <td v-for="(et, index) of documentation.evaluation.evaluationTopics"
                          :key="getTableDataKey(index, 37000 + 50 * groupNum)" style="width: 16%">
                        <b v-if="et.primaryComparisonGroup === 'Gruppe ' + groupNum " class="highlight-cell">Gruppe
                          {{ groupNum }}</b>
                        <b v-else-if="et.higherComparisonGroup === 'Gruppe ' + groupNum" class="highlight-less-cell">Gruppe
                          {{ groupNum }}&nbsp;&#9650;</b>
                        <b v-else-if="et.lowerComparisonGroup === 'Gruppe ' + groupNum" class="highlight-less-cell">Gruppe
                          {{ groupNum }}&nbsp;&#9660;</b>
                        <i v-else style="color: lightgray">Gruppe {{ groupNum }}</i>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <table v-if="selectedDiffDoc.length > 0" v-for="(diffDoc, diffDocIndex) of selectedDiffDoc"
                         :key="getTableDataKey(parseInt(diffDoc.id, 16), 38000 * (diffDocIndex + 1))"
                         class="hover evaluation-topics">
                    <br><br>
                    <label class="ai-standard-text">
                      <i>{{ labels.documentationType[diffDoc.type].toUpperCase() }}
                        <span>({{ diffDoc.lastModifiedDate | moment('DD.MM.YYYY') }})
                      <md-tooltip md-direction="top"
                                  style="font-size: medium;">zuletzt geändert am {{
                          diffDoc.lastModifiedDate | moment('DD.MM.YYYY')
                        }} um {{ diffDoc.lastModifiedDate | moment('LTS') }} Uhr</md-tooltip>
                    </span>
                      </i>
                    </label>
                    <tr>
                      <td colspan="6">Name des Kindes: <span
                          class="highlight-comparison-cell">{{ child.masterdata.fullName }}</span></td>
                    </tr>
                    <tr>
                      <td v-for="(et, index) of diffDoc.evaluation.evaluationTopics"
                          :key="getTableDataKey(index, 39000)" style="width: 16%">
                        <b>{{ et.topic.replace(',', ', ') }}</b></td>
                    </tr>
                    <tbody v-for="groupNum in 6" :key="getTableDataKey(groupNum, 40000)">
                    <tr>
                      <td v-for="(et, index) of diffDoc.evaluation.evaluationTopics"
                          :key="getTableDataKey(index, 41000 + 50 * groupNum)" style="width: 16%">
                        <b v-if="et.primaryComparisonGroup === 'Gruppe ' + groupNum " class="highlight-comparison-cell">Gruppe
                          {{ groupNum }}</b>
                        <b v-else-if="et.higherComparisonGroup === 'Gruppe ' + groupNum"
                           class="highlight-less-comparison-cell">Gruppe {{ groupNum }}&nbsp;&#9650;</b>
                        <b v-else-if="et.lowerComparisonGroup === 'Gruppe ' + groupNum"
                           class="highlight-less-comparison-cell">Gruppe {{ groupNum }}&nbsp;&#9660;</b>
                        <i v-else style="color: lightgray">Gruppe {{ groupNum }}</i>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </md-tab>
              </md-tabs>
              <md-tabs id="seldak-evaluation-tabs" v-if="documentation.type === 'QUESTIONNAIRE_SELDAK'">
                <md-tab id="sheet-one-sum-values-seldak" md-label="Summenwerte">
                  <table class="hover evaluation-topics">
                    <tr>
                      <td colspan="8" style="border: 0;">Name des Kindes: <span
                          class="highlight-cell">{{ child.masterdata.fullName }}</span></td>
                      <td colspan="7" style="border: 0;">Datum: <span
                          class="highlight-cell">{{ documentation.endEditing | moment('DD.MM.YYYY') }}</span></td>
                    </tr>
                    <tr>
                      <td colspan="12"><b>Fragen</b></td>
                      <td style="text-align: center;"><b>Summe</b></td>
                      <td><b>Bereich</b></td>
                    </tr>
                    <tbody v-for="(et, index) of documentation.evaluation.evaluationTopics"
                           :key="getTableDataKey(index, 42000 + index * 50)">
                    <tr>
                      <td v-for="i in (12 - et.questions.length)" :key="getTableDataKey(i, 43000 + index * 50)"></td>
                      <td style="text-align: center;" v-for="(q, ind) of et.questions" v-html="q.index"
                          :key="getTableDataKey(ind, 44000 + index * 50)"></td>
                      <td style="text-align: center;" class="highlight-cell" v-html="et.totalSum" rowspan="2"></td>
                      <td v-html="et.topic" rowspan="2"></td>
                    </tr>
                    <tr>
                      <td v-for="i in (12 - et.questions.length)" :key="getTableDataKey(i, 45000 + index * 50)"></td>
                      <td style="text-align: center;" class="highlight-cell" v-for="(q, ind) of et.questions"
                          v-html="q.score" :key="getTableDataKey(ind, 46000 + index * 50)"></td>
                    </tr>
                    <tr v-if="selectedDiffDoc.length > 0" v-for="(diffDoc, diffDocIndex) of selectedDiffDoc"
                        :key="getTableDataKey(parseInt(diffDoc.id, 16), 47000 * (diffDocIndex + 1))" class="even-row">
                      <td v-for="i in (12 - diffDoc.evaluation.evaluationTopics[index].questions.length)"
                          :key="getTableDataKey(i, (48000 + index * 50) * (diffDocIndex + 1))"></td>
                      <td style="text-align: center;" class="highlight-comparison-cell"
                          v-for="(q, ind) of diffDoc.evaluation.evaluationTopics[index].questions" v-html="q.score"
                          :key="getTableDataKey(ind, 49000 + index * 50)"></td>
                      <td style="text-align: center;" class="highlight-comparison-cell"
                          v-html="diffDoc.evaluation.evaluationTopics[index].totalSum"></td>
                      <td>{{ diffDoc.evaluation.evaluationTopics[index].topic }} <span
                          class="highlight-comparison-cell">{{ labels.documentationType[diffDoc.type].toUpperCase() }} ({{
                          diffDoc.lastModifiedDate | moment('DD.MM.YYYY')
                        }})</span></td>
                    </tr>
                    </tbody>
                  </table>

                </md-tab>
                <md-tab id="sheet-two-norm-table-seldak" md-label="Normtabelle">
                  <table class="hover evaluation-topics">
                    <tr>
                      <td colspan="1" style="border: 0;">Name des Kindes: <span
                          class="highlight-cell">{{ child.masterdata.fullName }}</span></td>
                      <td colspan="6" style="border: 0;">Datum: <span
                          class="highlight-cell">{{ documentation.endEditing | moment('DD.MM.YYYY') }}</span></td>
                    </tr>
                    <tbody v-for="(et, index) of documentation.evaluation.evaluationTopics"
                           :key="getTableDataKey(index, 50000)">
                    <tr>
                      <td v-html="et.topic"></td>
                      <td v-for="groupNum in 6" :key="getTableDataKey(groupNum, 51000 + index * 20)"
                          style="text-align: center;">
                        <b v-if="et.primaryComparisonGroup === 'Gruppe ' + groupNum" class="highlight-cell">Gruppe
                          {{ groupNum }}</b>
                        <b v-else-if="et.higherComparisonGroup === 'Gruppe ' + groupNum" class="highlight-less-cell">Gruppe
                          {{ groupNum }}&nbsp;&#9664;</b>
                        <b v-else-if="et.lowerComparisonGroup === 'Gruppe ' + groupNum" class="highlight-less-cell">&#9654;&nbsp;Gruppe
                          {{ groupNum }}</b>
                        <i v-else style="color: lightgray">Gruppe {{ groupNum }}</i>
                      </td>
                    </tr>
                    <tr v-if="selectedDiffDoc.length > 0" v-for="(diffDoc, diffDocIndex) of selectedDiffDoc"
                        :key="getTableDataKey(parseInt(diffDoc.id, 16), 52000 * (diffDocIndex + 1))" class="odd-row">
                      <td>{{ diffDoc.evaluation.evaluationTopics[index].topic }} <span
                          class="highlight-comparison-cell">{{ labels.documentationType[diffDoc.type].toUpperCase() }} ({{
                          diffDoc.lastModifiedDate | moment('DD.MM.YYYY')
                        }})</span></td>
                      <td v-for="groupNum in 6"
                          :key="getTableDataKey(groupNum, (53000 + index * 20) * (diffDocIndex + 1))"
                          style="text-align: center;">
                        <b v-if="diffDoc.evaluation.evaluationTopics[index].primaryComparisonGroup === 'Gruppe ' + groupNum"
                           class="highlight-comparison-cell">Gruppe {{ groupNum }}</b>
                        <b v-else-if="diffDoc.evaluation.evaluationTopics[index].higherComparisonGroup === 'Gruppe ' + groupNum"
                           class="highlight-less-comparison-cell">Gruppe {{ groupNum }}&nbsp;&#9664;</b>
                        <b v-else-if="diffDoc.evaluation.evaluationTopics[index].lowerComparisonGroup === 'Gruppe ' + groupNum"
                           class="highlight-less-comparison-cell">&#9654;&nbsp;Gruppe {{ groupNum }}</b>
                        <i v-else style="color: lightgray">Gruppe {{ groupNum }}</i>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </md-tab>
              </md-tabs>
            </div>
          </md-tab>
          <md-tab v-if="!documentation.type.includes('QUESTIONNAIRE')" id="tab-content"
                  :md-label="labels.documentationType[documentation.type]">
            <vue-editor v-model="documentation.document.content"></vue-editor>
            <div v-if="selectedDiffDoc.length > 0">
              <div v-for="(diffDoc, diffDocIndex) of selectedDiffDoc"
                   :key="getTableDataKey(parseInt(diffDoc.id, 16), 54000 * (diffDocIndex + 1))">
                <br>
                <label class="ai-standard-text">
                  <i>{{ labels.documentationType[diffDoc.type].toUpperCase() }}
                    <span>({{ diffDoc.lastModifiedDate | moment('DD.MM.YYYY') }})
                      <md-tooltip md-direction="top"
                                  style="font-size: medium;">zuletzt geändert am {{
                          diffDoc.lastModifiedDate | moment('DD.MM.YYYY')
                        }} um {{ diffDoc.lastModifiedDate | moment('LTS') }} Uhr</md-tooltip>
                    </span>
                  </i>
                </label>
                <vue-editor v-model="diffDoc.document.content" disabled></vue-editor>
              </div>
            </div>
          </md-tab>
        </md-tabs>
      </md-app-content>
    </md-app>

    <md-dialog :md-click-outside-to-close="false" :md-active.sync="confirmSaveChangedDataDialog">
      <div v-if="documentation" class="grid-x grid-padding-x grid-padding-y">
        <md-dialog-title>
          <DocumentationIconView :type="documentation.type"/>&nbsp;&nbsp;&nbsp;
          Änderungen an Dokumentation <span
            style="color: crimson; font-size: larger">{{
            labels.documentationType[documentation.type].toUpperCase()
          }}</span>
          bestätigen
        </md-dialog-title>
        <div class="cell">
          <md-content>Es wurden Änderungen an der Dokumentation für das Kind <span
              class="group-title">{{ child.masterdata.fullName }}</span>
            durchgeführt. Sollen diese gespeichert werden?
          </md-content>
          <br>
          <md-progress-bar md-mode="indeterminate" v-if="sending"/>
        </div>
        <div class="cell text-center">
          <button @click="onSaveChanges" class="button success" :disabled="sending"><i class="fi-save"></i>&nbsp;&nbsp;&nbsp;Ja,
            speichern
          </button>
          <button class="button alert" style="margin-left: 1rem;"
                  @click="onCancelSaveChanges"
                  type="button"
                  :disabled="sending">
            <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
          </button>
        </div>
        <button class="close-button" type="button" @click="confirmSaveChangedDataDialog = false">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </md-dialog>

    <ChildBriefRecord v-if="!printMode" :child="child" :groups="groups"></ChildBriefRecord>
  </div>
</template>

<script>

import jQuery from 'jquery';
import ImageView from '../../components/views/ImageView';
import DocumentationIconView from "../views/DocumentationIconView";
import Spinner from 'vue-simple-spinner';
import SessionTime from '../animations/SessionTime';
import ChildBriefRecord from '../../components/fabs/ChildBriefRecord';
import QuestionAnswersView from '../views/documentation/QuestionAnswersView';

import {validationMixin} from 'vuelidate';
import Vue from 'vue'

import {
  required,
} from 'vuelidate/lib/validators';
import {Md5} from 'ts-md5/dist/md5';
import DocumentationService from "../../services/DocumentationService";
import GroupService from '../../services/GroupService';
import HttpErrorHandler from '../../services/HttpErrorHandler';

import {VueEditor} from 'vue2-editor';
import Employee from "@/entities/Employee";

const filterByType = (items, term, id) => {
  if (term) {
    return items.filter(item => (item.type.includes(term) && !item.id.includes(id)));
  }

  return items;
};

export default {
  name: 'EditDocumentationDialog',
  mixins: [validationMixin],

  components: {
    ImageView,
    DocumentationIconView,
    'vue-simple-spinner': Spinner,
    SessionTime,
    ChildBriefRecord,
    QuestionAnswersView,
    VueEditor,
  },

  props: {
    child: {
      required: true
    },
    doc: {
      required: true
    },
    fullDoc: {
      required: true
    },
    mode: {
      required: true
    },
  },

  watch: {
    doc() {
      this.setMainSectionsVisibility();
      this.setMD5Hash();
      this.modifyMarkers();
      this.formatStartEditingDate();
      this.formatEndEditingDate();

      if (this.isAuthenticated) {
        this.restoreUserSettings();
        this.toggleDarkMode();
      }
    },

    darkMode() {
      if (this.isAuthenticated) {
        this.toggleDarkMode();
      }
    },

    fullDoc() {
      this.filterFullDoc();
    },
  },

  mounted() {
    this.setMD5Hash();
  },

  created() {
    this.getAllOrganizationGroups();
    this.formatStartEditingDate();
    this.formatEndEditingDate();
    this.initDatePickerSyncHandler(true);
  },

  beforeDestroy() {
    this.initDatePickerSyncHandler(false);
  },

  data: () => ({
    invalid: true,
    sending: false,
    success: false,

    printMode: false,
    selectedEvaluationTabId: '',

    startEditingDate: null,
    endEditingDate: null,

    documentationMd5Hash: null,
    confirmSaveChangedDataDialog: false,
    documentation: null,

    selectedDiffDocIds: [],
    selectedDiffDoc: [],
    filteredFullDoc: [],
    questions: {},
    sections: {},

    coverTextFirst: {
      'NOTE': '',
      'PARENTS_TALK': '',
      'CHILD_OBSERVATION': '',
      'QUESTIONNAIRE_PERIK': 'Positive Entwicklung und Resilienz im Kindergartenalltag',
      'QUESTIONNAIRE_SELDAK': 'Sprachentwicklung und Literacy<br>&nbsp;&nbsp;&nbsp;&nbsp;bei deutschsprachig aufwachsenden Kindern',
      'QUESTIONNAIRE_SISMIK': 'Sprachverhalten und Interesse an Sprache<br>&nbsp;&nbsp;&nbsp;&nbsp;bei Migrantenkindern in Kindertageseinrichtungen',
    },

    coverTextSecond: {
      'NOTE': '',
      'PARENTS_TALK': '',
      'CHILD_OBSERVATION': '',
      'QUESTIONNAIRE_PERIK': 'Beobachtungsbogen',
      'QUESTIONNAIRE_SELDAK': 'Beobachtungsbogen',
      'QUESTIONNAIRE_SISMIK': 'Beobachtungsbogen',
    },

    groups: [],
    availableEmployees: [],

    darkModeState: false,
  }),

  validations: {
    documentation: {
      startEditing: {
        required,
      },
      endEditing: {
        required,
      },
    },
  },

  methods: {

    restoreUserSettings() {
      if (localStorage.getItem(this.user.md5 + '@darkMode')) {
        this.darkModeState = JSON.parse(localStorage.getItem(this.user.md5 + '@darkMode'));
        this.toggleDarkMode();
      }
    },

    toggleDarkMode() {
      this.$store.commit('darkMode', this.darkModeState);
      if (this.darkMode) {
        Vue.material.theming.theme = 'dark';
      } else {
        Vue.material.theming.theme = 'default';
      }
      localStorage.setItem(this.user.md5 + '@darkMode', this.darkMode);

      HttpErrorHandler.maintainDarkMode(this, 10);
    },

    diffQuestionFor(question, diffDoc) {
      if (diffDoc) {
        let key = question.number + question.index + diffDoc.id;
        if (this.questions[key]) {
          return this.questions[key];
        }

        if (diffDoc.document && diffDoc.document.questionnaire) {
          for (let mainSection of diffDoc.document.questionnaire.mainSections) {
            for (let section of mainSection.sections) {
              for (let q of section.questions) {
                if (q.number === question.number && q.index === question.index) {
                  let key = q.number + q.index + diffDoc.id;
                  this.questions[key] = q;
                  return q;
                }
              }
            }
          }

        }
      }
      return question;
    },

    diffSectionFor(section, diffDoc) {
      if (diffDoc) {
        let key = section.mark + section.heading + diffDoc.id;
        if (this.sections[key]) {
          return this.sections[key];
        }

        if (diffDoc.document && diffDoc.document.questionnaire) {
          for (let mainSection of diffDoc.document.questionnaire.mainSections) {
            for (let s of mainSection.sections) {
              if (s.mark === section.mark && s.heading === section.heading) {
                let key = section.mark + section.heading + diffDoc.id;
                this.sections[key] = s;
                return s;
              }
            }
          }
        }
      }
      return null;
    },

    resizeOptionsPopup() {
      const width = jQuery('#document-comparision-select').width() * 0.9;
      jQuery('.md-select-menu.md-menu-content-bottom-start').css({'max-width': width.toString() + 'px'});
    },

    selectDiffDoc() {
      this.selectedDiffDoc = [];
      this.filteredFullDoc.forEach(doc => {
        this.selectedDiffDocIds.forEach(docId => {
          if (doc.id === docId) {
            this.selectedDiffDoc.push(JSON.parse(JSON.stringify(doc)));
          }
        })
      })

      this.forceRenderUpdate();
    },

    onStepChanged() {
      setTimeout(() => this.forceRenderUpdate(), 1000);
    },

    async forceRenderUpdate() {
      await this.$nextTick();
      let random_id = Math.floor(Math.random() * 1000000);
      jQuery('#tab-cover').append("<p id='" + random_id + "'></p>");
      jQuery(random_id).remove();

      random_id = Math.floor(Math.random() * 10000);
      jQuery('#tab-concept').append("<p id='" + random_id + "'></p>");
      jQuery(random_id).remove();

      random_id = Math.floor(Math.random() * 10000);
      jQuery('#tab-questionnaire').append("<p id='" + random_id + "'></p>");
      jQuery(random_id).remove();

      random_id = Math.floor(Math.random() * 10000);
      jQuery('#tab-summary').append("<p id='" + random_id + "'></p>");
      jQuery(random_id).remove();

      random_id = Math.floor(Math.random() * 10000);
      jQuery('#tab-evaluation').append("<p id='" + random_id + "'></p>");
      jQuery(random_id).remove();

      if (this.documentation.document && this.documentation.document.questionnaire) {
        for (let mainSection of this.documentation.document.questionnaire.mainSections) {
          for (let section of mainSection.sections) {
            random_id = Math.floor(Math.random() * 10000);
            jQuery('#' + section.mark).append("<p id='" + random_id + "'></p>");
            jQuery(random_id).remove();
          }
        }
      }
    },

    filterFullDoc() {
      this.filteredFullDoc = filterByType(this.fullDoc, this.documentation.type, this.documentation.id ? this.documentation.id : 0);
    },

    initDatePickerSyncHandler(init) {
      if (init) {
        setTimeout(() => {
          jQuery('#start-editing-date-dp').children('input').bind('blur', () => {
            this.startEditingDate = jQuery('#start-editing-date-dp').children('input').val();
            this.writeBackDates();
          });
          jQuery('#end-editing-date-dp').children('input').bind('blur', () => {
            this.endEditingDate = jQuery('#end-editing-date-dp').children('input').val();
            this.writeBackDates();
          });
        }, 500);
      } else {
        jQuery('#start-editing-date-dp').children('input').unbind('blur');
        jQuery('#end-editing-date-dp').children('input').unbind('blur');
      }
    },

    updateAvailableEmployees() {
      // Select only employees from the child's facility
      const availableChildFacilityEmployees = [];
      this.availableOrganizationEmployees.forEach(employee => {
        if (employee.facilityReference === this.child.facilityReference) {
          availableChildFacilityEmployees.push(employee);
        }
      })
      this.availableEmployees = availableChildFacilityEmployees;
      if (this.documentation) {
        let newEmployees = availableChildFacilityEmployees;

        // Complete list by editor names which were not found in current staff
        this.documentation.editors.forEach((editor) => {
          let found = false;
          availableChildFacilityEmployees.forEach(employee => {
            if (employee.masterdata.fullName === editor) {
              found = true;
            }
          })

          if (!found) {
            let newEmployee = JSON.parse(JSON.stringify(Employee.employee));
            newEmployee.masterdata.fullName = editor;
            newEmployee.exited = true;
            newEmployees.push(newEmployee);
          }
        });

        // Sort new employee list by full name
        let availableEmployeesFullNames = [];
        newEmployees.forEach(employee => availableEmployeesFullNames.push(employee.masterdata.fullName));
        availableEmployeesFullNames.sort();

        this.availableEmployees = [];
        availableEmployeesFullNames.forEach(name => {
          newEmployees.forEach(employee => {
            if (employee.masterdata.fullName === name) {
              this.availableEmployees.push(employee);
            }
          });
        });
      }
    },

    getTableDataKey(index, offset) {
      return (index + offset);
    },

    onPrint() {
      this.printMode = true;
      if (this.isDevEnv) {
        jQuery('#__vconsole').hide();
      }
      jQuery('#documentation-tabs').children('.md-tabs-navigation').hide();
      jQuery('#sismik-evaluation-tabs').children('.md-tabs-navigation').hide();
      jQuery('#seldak-evaluation-tabs').children('.md-tabs-navigation').hide();
      jQuery('#perik-evaluation-tabs').children('.md-tabs-navigation').hide();

      setTimeout(() => window.print(), 250);

      window.onafterprint = () => {
        this.printMode = false;
        if (this.isDevEnv) {
          jQuery('#__vconsole').show();
        }
        jQuery('#documentation-tabs').children('.md-tabs-navigation').show();
        jQuery('#sismik-evaluation-tabs').children('.md-tabs-navigation').show();
        jQuery('#seldak-evaluation-tabs').children('.md-tabs-navigation').show();
        jQuery('#perik-evaluation-tabs').children('.md-tabs-navigation').show();

        setTimeout(() => window.dispatchEvent(new Event('resize')), 150);
      }
    },

    getAllOrganizationGroups() {
      this.sending = true;
      GroupService.getAllOrganizationGroups()
          .then(response => {
            this.groups = response.data;
            this.sending = false;
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der Gruppen für Träger ' + this.organization.name);
            this.sending = false;
          })
    },

    formatStartEditingDate() {
      if (this.startEditingDate === null) {
        if (this.documentation && this.documentation.startEditing) {
          this.startEditingDate = Vue.moment(this.documentation.startEditing).format('DD.MM.YYYY');
        }
      }
    },

    formatEndEditingDate() {
      if (this.endEditingDate === null) {
        if (this.documentation && this.documentation.endEditing) {
          this.endEditingDate = Vue.moment(this.documentation.endEditing).format('DD.MM.YYYY');
        }
      }
    },

    onOpenEndEditingDatePicker() {
      if ((this.documentation.startEditing && !this.documentation.endEditing) ||
          (this.startEditingDate && !this.endEditingDate)) {
        this.documentation.endEditing = this.documentation.startEditing;
        this.endEditingDate = this.startEditingDate;
      }
    },

    setMD5Hash() {
      if (this.doc) {
        if (!this.documentation) {
          this.documentation = JSON.parse(JSON.stringify(this.doc));
          this.documentationMd5Hash = Md5.hashAsciiStr(JSON.stringify(this.documentation));
          this.filterFullDoc();
        }
        this.updateAvailableEmployees();
      }
    },

    modifyMarkers() {
      if (this.documentation && this.documentation.document.questionnaire && this.documentation.document.questionnaire.mainSections) {
        setTimeout(() => {
          for (let mainSection of this.documentation.document.questionnaire.mainSections) {
            for (let section of mainSection.sections) {
              jQuery('span.md-stepper-label:contains(' + section.heading + ')').parent().parent().children().first().html(section.mark)
            }
          }
        }, 250);
      }
    },

    getValidationClass(fieldName) {
      let field = this.$v.documentation[fieldName];
      if (field) {
        return {
          'md-invalid': (field.$invalid && field.$dirty) ||
              (field.$model === 'Invalid date' && field.$dirty)
        }
      }
    },

    writeBackDates() {
      if (this.startEditingDate) {
        this.documentation.startEditing = Vue.moment(this.startEditingDate, 'DD.MM.YYYY').format('YYYY-MM-DD');
      } else {
        this.documentation.startEditing = null;
      }

      if (this.endEditingDate) {
        this.documentation.endEditing = Vue.moment(this.endEditingDate, 'DD.MM.YYYY').format('YYYY-MM-DD');
      } else {
        this.documentation.endEditing = null;
      }
    },

    validateDocumentation() {
      this.writeBackDates();

      this.$v.$touch();
      this.invalid = this.$v.$invalid;

      if (!this.invalid) {
        if (this.mode === 'add') {
          this.addDocumentationForChild();
        }
        if (this.mode === 'update') {
          this.updateDocumentationForChild();
        }
      } else {
        this.confirmSaveChangedDataDialog = false;
        jQuery('div:contains("Deckblatt")').trigger('click');
      }
    },

    updateDocumentationForChild() {
      this.sending = true;
      this.success = false;
      DocumentationService.updateDocumentation(this.child.id, this.documentation)
          .then(response => {
            this.success = true;
            this.sending = false;
            this.$emit("updateDocumentationSuccess", response.data);
            this.$store.commit('successMsg', 'Die Änderungen der Dokumentation für das Kind ' + this.child.masterdata.fullName + ' wurden erfolgreich durchgeführt!');
            this.documentation = response.data;
            this.documentationMd5Hash = Md5.hashAsciiStr(JSON.stringify(this.documentation));
            if (this.confirmSaveChangedDataDialog) {
              this.confirmSaveChangedDataDialog = false;
              this.closeDialog();
            }
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Speichern der Änderungen für Dokumentation von Kind '
                + this.child.masterdata.fullName);
            this.sending = false;
          })
    },

    evaluateDocumentationForChild() {
      this.sending = true;
      this.success = false;
      DocumentationService.evaluateDocumentation(this.child.id, this.documentation)
          .then(response => {
            this.success = true;
            this.sending = false;
            this.documentation = response.data;
            this.documentationMd5Hash = Md5.hashAsciiStr(JSON.stringify(this.documentation));
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Aktualisieren der Auswertung für Dokumentation von Kind '
                + this.child.masterdata.fullName);
            this.sending = false;
          })
    },

    addDocumentationForChild() {
      this.sending = true;
      this.success = false;
      DocumentationService.addDocumentation(this.child.id, this.documentation)
          .then(response => {
            this.success = true;
            this.sending = false;
            this.$emit("updateDocumentationSuccess", response.data);
            this.$store.commit('successMsg', 'Die Dokumentation für das Kind ' + this.child.masterdata.fullName + ' wurde erfolgreich hinzugefügt!');
            this.documentation = response.data;
            this.documentationMd5Hash = Md5.hashAsciiStr(JSON.stringify(this.documentation));
            if (this.confirmSaveChangedDataDialog) {
              this.confirmSaveChangedDataDialog = false;
              this.closeDialog();
            }
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Speichern der Änderungen für Dokumentation von Kind '
                + this.child.masterdata.fullName);
            this.sending = false;
          })
    },

    onSaveChanges() {
      this.validateDocumentation();
    },

    onCancelSaveChanges() {
      this.confirmSaveChangedDataDialog = false;
      this.$router.history.go(-1);
    },

    closeDialog() {
      this.writeBackDates();
      if (this.canWrite && this.documentationMd5Hash !== Md5.hashAsciiStr(JSON.stringify(this.documentation)) && !this.child.archived) {
        this.confirmSaveChangedDataDialog = true;
      } else {
        this.$v.$reset();
        this.startEditingDate = null;
        this.endEditingDate = null;
        this.$router.history.go(-1);
      }
    },

    onTabChanged(tabId) {
      this.setMainSectionsVisibility();
      if (tabId === 'tab-evaluation') {
        this.evaluateDocumentationForChild();
      }

      jQuery('.ql-link').remove();
      jQuery('.ql-image').remove();
      jQuery('.ql-video').remove();
      jQuery('.ql-clean').remove();

      this.forceRenderUpdate();
    },

    setMainSectionsVisibility() {
      if (this.documentation && this.documentation.document &&
          this.documentation.document.questionnaire && this.documentation.document.questionnaire.mainSections) {
        for (let mainSection of this.documentation.document.questionnaire.mainSections) {
          if (!mainSection.visible) {
            setTimeout(() => {
              jQuery('.md-stepper-content.md-active').children('.md-steppers').children('.md-steppers-navigation').attr('style', 'display: none');
              jQuery('#tab-questionnaire').children('.md-steppers').children('.md-steppers-navigation').attr('style', 'display: none');

              this.modifyMarkers();
            }, 250);
          }
        }
      }
    },

    getAnswersSize(length) {
      let offset = 0;
      if (this.selectedDiffDoc.length > 0) {
        offset = 30;
      }
      return 'width: ' + String(95 * length + offset) + 'px';
    },

    getSectionLabel(section) {
      return section.heading;
    },

    getSectionIcon(section) {
      let sectionIcons = {1: 'looks_one', 2: 'looks_two', 3: 'looks_3', 4: 'looks_4', 5: 'looks_5'};
      return sectionIcons[section.mark];
    },
  },

  computed: {

    isAuthenticated() {
      return this.$store.getters.authenticated
    },

    darkMode() {
      return this.$store.getters.darkMode;
    },

    isAdmin() {
      return this.$store.getters.admin
    },

    isSage() {
      return this.$store.getters.sage
    },

    isBeta() {
      return this.$store.getters.beta
    },

    user() {
      return this.$store.getters.user;
    },

    canWrite() {
      return this.$store.getters.canWrite
    },

    organization() {
      return this.$store.getters.organization;
    },

    availableOrganizationEmployees() {
      return this.$store.getters.availableOrganizationEmployees
    },

    labels() {
      return this.$store.getters.labels
    },

    isDevEnv() {
      return process.env.NODE_ENV === 'development';
    },
  },
};
</script>

<style scoped>

#document-cover-table-id tr:hover, .md-theme-dark #document-cover-table-id tr:hover {
  background-color: transparent !important;
}

.compare-title {
  margin-top: 25px;
  text-align: right;
}

.compare-reset-button {
  margin-top: 25px;
  text-align: left;
}

.evaluation-topics td span {
  font-size: large;
  font-weight: 500;
}

.evaluation-topics td {
  border: 1px solid darkgrey;
  padding: 0.5rem;
}

.evaluation-topics {
  border-collapse: collapse;
}

.reset-cell:hover {
  transition: color 0.5ms ease-in-out, text-decoration 0.5ms ease-in-out;
  color: crimson;
  cursor: pointer;
  text-decoration: line-through;
}

p {
  margin-bottom: 0;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-size: 0.9rem;
}

h5 {
  font-weight: 400;
  font-size: large;
  color: cadetblue;
  margin-bottom: 0;
  margin-top: 5px;
}

tbody th, tbody td {
  padding: 0;
}

table {
  display: table;
  border-collapse: separate;
  border-spacing: 2px;
  border-color: grey;
  empty-cells: hide;
  width: 100%;
  margin: 0;
  font-weight: 300;
  font-size: medium;
}

.md-fab-label {
  font-size: 1.5rem;
  font-weight: 300;
  color: dimgrey;
}

.md-radio-label {
  font-weight: 300;
  margin: 0;
}

textarea:disabled, .md-theme-dark textarea:disabled {
  background-color: transparent;
  color: cornflowerblue;
  font-style: italic;
}

.md-theme-dark .highlight-cell {
  color: #B9F6CA;
}

.highlight-cell {
  color: seagreen;
}

.md-theme-dark .highlight-less-cell {
  color: rgba(185, 246, 202, 0.7);
  font-weight: 400;
}

.highlight-less-cell {
  color: rgba(46, 139, 87, 0.7);
  font-weight: 400;
}

.disabled-comparison-cell, .md-theme-dark .disabled-comparison-cell {
  color: #7b7b7b;
  font-style: italic;
  font-size: unset !important;
  font-weight: unset !important;
}

.highlight-comparison-cell, .md-theme-dark .highlight-comparison-cell {
  color: cornflowerblue;
  font-style: italic;
  font-size: unset !important;
  font-weight: unset !important;
}

.highlight-less-comparison-cell, .md-theme-dark .highlight-less-comparison-cell {
  color: rgba(100, 149, 237, 0.7);
  font-style: italic;
  font-size: unset !important;
  font-weight: unset !important;
}

.md-theme-dark .even-row {
  background-color: #323232;
}

.even-row {
  background-color: #f1f1f1;
}

.md-theme-dark .odd-row {
  background-color: transparent;
}

.odd-row {
  background-color: transparent;
}


</style>

<style>
.md-radio-container {
  margin-left: 1rem !important;
}

.md-radio {
  margin: 16px 16px 16px 16px !important;
}

.accordion-title {
  padding: 10px;
}

.concept h1 {
  font-size: 2rem;
  color: cadetblue;
}

.concept h2 {
  font-size: 1.5rem;
  color: cadetblue;
}

.md-stepper-label {
  font-size: 1rem;
  font-weight: 300;
}

.md-steppers.md-theme-default .md-stepper-header.md-active .md-stepper-number {
  width: 36px;
  height: 36px;
  border-radius: 36px;
  line-height: 36px;
  font-size: 22px;
  background-color: darkseagreen;
}

.md-steppers.md-theme-default .md-stepper-number {
  width: 36px;
  height: 36px;
  border-radius: 36px;
  line-height: 36px;
  font-size: 22px;
  background-color: darkseagreen;
}

.md-steppers.md-theme-default .md-stepper-header.md-done .md-stepper-number {
  width: 36px;
  height: 36px;
  border-radius: 36px;
  line-height: 36px;
  font-size: 22px;
  background-color: darkseagreen;
}

.concept p {
  font-size: 0.9rem;
  font-weight: 300;
  padding: 0;
}

.concept li {
  font-size: 0.9rem;
  font-weight: 300;
}

.md-tabs-navigation .md-button {
  max-width: 800px;
  min-width: 72px;
}

.md-tabs.md-theme-default .md-tabs-navigation .md-button.md-active, .md-tabs.md-theme-default .md-tabs-navigation .md-button, .md-button md-tab-nav-button md-theme-default md-active md-icon-label {
  max-width: 800px;
  min-width: 72px;
}

.time-stamp {
  color: cornflowerblue;
  padding-left: 2rem;
  position: relative;
  top: -5px;
}
</style>
